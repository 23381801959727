<template>
  <div class="view-container">
    <div class="view">
      <div class="view-body">
        <el-form
            :model="formData"
            class="el-table--customStyle d-flex"
        >
          <el-form-item
              class="w-100"
              required
          >
            <el-input
                :class="checkinputRequired('name') ? 'validate-error' : ''"
                :placeholder="$t('system.add_language')"
                name="name"
                v-model="formData.name"
            >
              <template slot="prepend"><b>{{ $t(`system.add_language`) }}</b></template>
            </el-input>
            <small
                class="help-block"
                v-if="backend_errors.name && !checkinputRequired('name')"
            >
              <span v-html="validationTranslate(backend_errors.name[0])"></span>
            </small>
          </el-form-item>
          <el-form-item
              class="w-100 ml-3"
              required
          >
            <el-input
                :class="checkinputRequired('shortcut') ? 'validate-error' : ''"
                :placeholder="$t('system.shortcut')"
                name="shortcut"
                v-model="formData.shortcut"
            >
              <template slot="prepend"><b>{{ $t(`system.add_shortcut`) }}</b></template>
            </el-input>
            <small
                class="help-block"
                v-if="backend_errors.shortcut && !checkinputRequired('shortcut')"
            >
              <span v-html="validationTranslate(backend_errors.shortcut[0])"></span>
            </small>
          </el-form-item>
          <el-form-item>
            <el-button
                @click="addLanguage"
                class="ml-3"
                type="primary"
            >
              {{ $t('system.add') }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="view">
      <div class="view-body">
        <static-table
            :backend_errors="backend_error_table"
            :data="model"
            :params="params"
            :thead="thead"
            @cancelEdit="cancelEdit"
            @confirmEdit="confirmEdit"
            @editElement="editElement"
            @removeElement="removeElement"
            @sortChange="sortChange"
            @translateByElement="translateByElement"
        ></static-table>
        <el-card
            class="pagination-container mt-3"
            shadow="never"
        >
          <el-pagination
              :current-page="params.page"
              :page-size="params.per_page"
              :page-sizes="[10, 30, 50, 100, 300]"
              :total="total"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              background
              layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import _ from 'lodash';
import swal from 'sweetalert2';
import notification from '../../notification/notify';
import StaticTable from '../../components/ABtable/StaticTable.vue';

export default {
  name: 'LanguagesIndex',
  components: {
    StaticTable,
  },
  data() {
    return {
      formData: {
        name: '',
        shortcut: '',
      },
      model: [],
      newLanguage: '',
      newShourtcut: '',
      thead: [
        {
          label: 'system.name',
          key: 'name',
          edit: true,
          size: 'small',
          sort: true,
        },
        {
          label: 'system.shortcut',
          key: 'shortcut',
          edit: true,
          size: 'small',
          sort: true,
        },
        {
          label: '',
          key: 'action',
          sort: false,
          type: 'action',
          buttonTranslate: true,
        },
      ],
      params: {
        column: 'id',
        direction: 'desc',
        per_page: 10,
        page: 1,
      },
      total: 0,
      backend_error_table: {},
      backend_errors: {},
      inputRequiredEmpty: [],
    };
  },
  beforeMount() {
    this.fetchData();
  },
  methods: {
    ...mapActions('translate', ['getLanguages', 'addToLanguages', 'updateLanguage', 'deletefromLanguages']),
    fetchData() {
      let vm = this;
      vm.getLanguages(vm.params).then(function (response) {
        let p = response.meta;
        vm.params.per_page = parseInt(p.per_page);
        vm.params.page = parseInt(p.page);
        vm.total = p.total;
        vm.model = _.map(response.data, function (item) {
          item.edit = false;
          return item;
        });
      });
    },
    addLanguage() {
      let vm = this;
      vm.checkValidation().then(function () {
        let item = {
          shortcut: vm.formData.shortcut,
          name: vm.formData.name,
        };
        vm.addToLanguages(item).then(function () {
          vm.fetchData();
        }).catch(function (error) {
          vm.backend_errors = error.response.data.errors;
        });
      }).catch(function () {
        //
      });
    },
    sortChange(column) {
      if (column === this.params.column) {
        if (this.params.direction === 'desc') {
          this.params.direction = 'asc';
        } else {
          this.params.direction = 'desc';
        }
      } else {
        this.params.column = column;
        this.params.direction = 'asc';
      }
      this.fetchData();
    },
    editElement(row) {
      row.edit = true;
      row.originalNameCountry = row.name;
      row.originalNameShourtcut = row.shortcut;
    },
    confirmEdit(row) {
      let vm = this;
      vm.backend_error_table = {};
      if (row.originalNameCountry === row.name && row.originalNameShourtcut === row.shortcut) {
        row.edit = false;
      } else {
        vm.updateLanguage(row).then(function (response) {
          notification.notify(
              vm.$t('notify.success'),
              vm.$t('system.updated'),
              'success');
          row.edit = false;
        }).catch(function (error) {
          vm.backend_error_table = error.response.data.errors;
          vm.backend_error_table.id = row.id;
        });
      }
    },
    removeElement: function (element) {
      let vm = this;
      swal.fire({
        title: vm.$t('system.remove'),
        text: vm.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: vm.$t('system.yes'),
        cancelButtonText: vm.$t('system.no'),
      }).then(function (resp) {
        if (resp.value) {
          if (element.id) {
            vm.deletefromLanguages(element.id).then(function (response) {
              vm.fetchData();
              notification.notify(
                  vm.$t('notify.success'),
                  vm.$t('system.deleted'),
                  'success');
            }).catch(function (error) {
              notification.notify(
                  vm.$t('notify.error'),
                  vm.$t(error.response.data.error),
                  'error');
            });
          }
        }
      });
    },
    cancelEdit(row) {
      this.backend_error_table = {};
      row.name = row.originalNameCountry;
      row.shortcut = row.originalNameShourtcut;
      row.edit = false;
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.fetchData();
    },
    handleSizeChange(val) {
      this.params.per_page = val;
      this.fetchData();
    },
    translateByElement(row) {
      this.$router.push(`languages/${row.id}`);
    },
  },
};
</script>

<style scoped>

</style>
